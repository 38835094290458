@config "../../tailwind.config.ts";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#__next,
div[data-rk] {
  width: 100%;
  height: 100%;
}

/* FIXME: idk why but rainbowkit modal is translated to the right and top by 200px. This forces it to be centralized */
div[data-rk] > * {
  left: 0px !important;
  top: 0px !important;
}

/* make wallet connect modal higher than our modals */
wcm-modal {
  z-index: 1000;
  position: relative;
}

/* disable monaco editor command palette */
div[widgetid="editor.contrib.quickInputWidget"] {
  display: none !important;
}

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@keyframes shining {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

.animate-shine {
  animation: shining 2s linear infinite;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@layer components {
  .custom-scrollbar {
    --sb-track-color: transparent;
    --sb-thumb-color: #d1d7e3;
    --sb-radius: 3px;
    --sb-size: 14px;
    --sb-padding: 4px;
    --sb-padding-left: var(--sb-padding);
    --sb-padding-right: var(--sb-padding);
    --sb-padding-top: 2px;
    --sb-padding-bottom: 2px;
  }
  .custom-scrollbar::-webkit-scrollbar {
    width: calc(
      var(--sb-size) + calc(var(--sb-padding-left) + var(--sb-padding-right))
    );
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
    background: var(--sb-track-color);
    border-radius: var(--sb-radius);
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--sb-thumb-color);
    background-clip: padding-box;
    border-radius: var(--sb-radius);
    border-color: transparent;
    border-style: solid;
    border-width: var(--sb-padding-top) var(--sb-padding-right)
      var(--sb-padding-bottom) var(--sb-padding-left);
  }

  @supports not selector(::-webkit-scrollbar) {
    .custom-scrollbar {
      scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
  }

  .horizontal-line {
    @apply h-px bg-[#E4EBFF];
  }

  .collapsible-content[data-state="open"] {
    animation:
      collapsible-down 300ms cubic-bezier(0.22, 1, 0.36, 1),
      fadeIn 300ms ease-out;
  }
  .collapsible-content[data-state="closed"] {
    animation:
      fadeOut 300ms ease-out,
      collapsible-up 300ms ease-out;
  }
}

@layer utilities {
  .debug {
    outline: red solid 2px;
  }

  .ruled-grid {
    --grid-border: 1px;
    display: grid;
    grid-template-columns: 3rem auto 3rem;
    grid-template-rows: 2.5rem auto 3rem;
    border-collapse: collapse;
  }
  .ruled-grid__item {
    border-color: inherit;
    border-right-width: var(--grid-border);
    border-bottom-width: var(--grid-border);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .animate-spin {
    animation: spin 1.5s linear infinite;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slide-up {
    from {
      translate: 0 100%;
    }
    to {
      translate: 0 0%;
    }
  }
  @keyframes slide-down {
    from {
      translate: 0 0%;
    }
    to {
      translate: 0 100%;
    }
  }

  @keyframes collapsible-down {
    from {
      height: 0;
    }
    to {
      height: var(--radix-collapsible-content-height);
    }
  }

  @keyframes collapsible-up {
    from {
      height: var(--radix-collapsible-content-height);
    }
    to {
      height: 0;
    }
  }

  .DialogContent[data-state="open"] {
    animation:
      slide-up 300ms cubic-bezier(0.22, 1, 0.36, 1),
      fadeIn 300ms ease-out;
  }
  .DialogContent[data-state="closed"] {
    animation:
      fadeOut 300ms ease-out,
      slide-down 300ms ease-out;
  }

  .DialogOverlay[data-state="open"] {
    animation: fadeIn 300ms ease-out;
  }

  .DialogOverlay[data-state="closed"] {
    animation: fadeOut 300ms ease-in;
  }
}
